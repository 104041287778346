import "./hero.css";
import RocketImage from "../../../../assets/images/rocket.png";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="hero">
      <div className="heroLeft">
        <h2 className="text-headingColor font-medium text-5xl leading-relaxed">
          Kick-start your open source contributions
        </h2>
        <p className="text-2xl mt-4 text-bodyColor leading-relaxed">
          Let's promote and simplify open source, one step at a time
        </p>
        <div className="heroBtnGroup mt-4 flex flex-col lg:flex-row">
          <button
            onClick={() => navigate("/join-us")}
            className="py-3 px-6 rounded font-medium bg-primary text-white joinUsBtn"
          >
            Join Our Rocketship
          </button>
          <button
            onClick={() => {
              let aboutSection = document.querySelector("#aboutSection");
              aboutSection.scrollIntoView({ behavior: "smooth" });
            }}
            className="py-3 px-6 rounded font-medium lg:ml-8 border border-primary text-primary bg-primaryLight ourProjectsBtn"
          >
            More About Us
          </button>
        </div>
      </div>
      <div className="heroRight">
        <img src={RocketImage} alt="rocket-img" />
        {/* hero image will come here */}
      </div>
    </div>
  );
};

export default Hero;
