import IOSFLogo from "../../assets/logos/iosf-logo.svg";

const HeaderMobile = () => {
  return (
    <div className="shadow">
      <div className="header flex flex-wrap items-center w-full h-20 container">
        <div className="headerLogo flex-1">
          <div className="flex items-center cursor-pointer">
            <img src={IOSFLogo} alt="iosf-logo" />
            <span className="ml-4 text-xl">Indian Open Source Foundation</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderMobile;