import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import AboutSection from "./components/AboutSection/AboutSection";
import Hero from "./components/Hero/Hero";
import Paths from "./components/Paths/Paths";
import "./home.css";

const Home = () => {
  return (
    <>
      <Header />
      <div className="home container">
        <div>
          <Hero />
        </div>
        <section id="aboutSection">
          <AboutSection />
        </section>
        <section>
          <Paths />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;
