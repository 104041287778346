import { useEffect, useState } from "react";
import IOSFLogo from "../../assets/logos/iosf-logo.svg";
import { useNavigate, useLocation } from "react-router-dom";

const SELECTED_STYLES =
  "text-bodyColor border-b-4 border-b-primary font-medium";
const NON_SELECTED_STYLES = "text-bodyColor font-normal";

const HeaderDesktop = ({ links }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // set selected index top current link's index
    const currentLink = links.find((link) => link.url === pathname);
    setSelectedIndex(currentLink.index);
  }, [pathname, links]);

  return (
    <div className="shadow">
      <div className="header flex flex-wrap items-center w-full h-20 container">
        <div className="headerLogo flex-1">
          <div className="flex items-center">
            <img src={IOSFLogo} alt="iosf-logo" />
            <span className="ml-4 text-xl">Indian Open Source Foundation</span>
          </div>
        </div>
        <div className="headerLinks flex-2">
          <div className="flex justify-end items-center">
            {links.map((link, index) => (
              <div
                key={index}
                onClick={() => {
                  navigate(link.url);
                  setSelectedIndex(index);
                }}
                className={`px-2 mx-4 text-xl cursor-pointer 
                    ${
                      index === selectedIndex
                        ? SELECTED_STYLES
                        : NON_SELECTED_STYLES
                    } 
                  `}
              >
                {link.name}
              </div>
            ))}
            {/* <button className="mx-4 py-2 px-4 rounded text-white font-medium bg-primary">
              Join Us
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDesktop;
