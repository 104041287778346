import { GithubLogo, InstagramLogo, LinkedinLogo, TwitterLogo, YoutubeLogo } from "phosphor-react";
import IOSFLogo from "../../assets/logos/iosf-logo.svg";

const SOCIAL_LINKS = [
  {
    name: "Github",
    link: "https://github.com/IndianOpenSourceFoundation",
    icon: <GithubLogo size={32} />
  },
  {
    name: "Youtube",
    link: "https://www.youtube.com/channel/UCU16gJvjk-bB2RfwBIq2DSA",
    icon: <YoutubeLogo size={32} />
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/iosf/",
    icon: <LinkedinLogo size={32} />
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/iosf_india/",
    icon: <InstagramLogo size={32} />
  },
  {
    name: "Twitter",
    link: "https://twitter.com/iosf_india",
    icon: <TwitterLogo size={32} />
  },
]

const Footer = () => {

  const date = new Date();

  return (
    <footer className="py-20 text-bodyColor bg-[#fafafa]">
      <div className="lg:flex container">
        <div className="flex-1 flex items-center mb-8">
          <img height={50} width={50} src={IOSFLogo} alt="iosf-logo" />
          <div className="ml-4">
            {/* <p>Indian Open Source Foundation</p> */}
            <p className="text-sm text-bodyColorLight">To build a super powerful open source community and change the world with creativity</p>
          </div>
        </div>
        <div className="flex-1 flex lg:justify-end">
          {
            SOCIAL_LINKS.map((item, index) => (
              <div onClick={() => {
                window.open(item.link, '_blank', 'noopener,noreferrer');
              }} className="mr-4 cursor-pointer" key={index}>
                {item.icon}
              </div>
            ))
          }
        </div>
      </div>
      <div className="container">
        {/* <div className="mt-8 text-sm">ping@iosf.in</div> */}
        <div className="text-sm mt-8">Copyright {date.getFullYear()} - Indian Open Source Foundation</div>
      </div>
    </footer>
  )
}

export default Footer;