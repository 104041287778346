import JoinUsForm from "./components/JoinUsForm/JoinUsForm";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import "./joinUs.css";
import Thumb from "../../assets/images/thumb.png";

const JoinUs = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="xl:flex my-12">
          <div className="flex-1 flex flex-col justify-center">
            <div className="mt-4 rounded xl:shadow-lg xl:p-8">
              <h2 className="text-4xl font-medium text-headingColor">
                Join our rocketship
              </h2>
              <p className="text-xl my-4 text-bodyColor leading-relaxed">
                To be a part of India's most visionary open source community
              </p>
              <JoinUsForm />
            </div>
          </div>
          <div className="joinUsRight flex-1 flex flex-col justify-center items-center">
            <img src={Thumb} alt="thumb-img" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinUs;
