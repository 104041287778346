import "./aboutSection.css";
import ContributeIcon from "../../../../assets/images/contributeIcon.png";
import LearnIcon from "../../../../assets/images/learnIcon.png";
import MentorIcon from "../../../../assets/images/mentorIcon.png";
import StarImage from "../../../../assets/images/star.png";
import { useNavigate } from "react-router-dom";

const TOGETHER_WE_CONTENT = [
  {
    title: "Learn",
    description:
      "There’s always room for learning it helps leveling up our products",
    icon: LearnIcon,
  },
  {
    title: "Contribute",
    description: "Contribute to dev and design projects that solve a problem",
    icon: ContributeIcon,
  },
  {
    title: "Mentor",
    description:
      "Mentoring gives us an opportunity to share knowledger and grow together.",
    icon: MentorIcon,
  },
];

const AboutSection = () => {
  const navigate = useNavigate();

  return (
    <div className="aboutSection">
      <div className="aboutSectionContent">
        <div className="aboutSectionLeft">
          <h2 className="text-4xl font-medium text-headingColor">
            Little more about us
          </h2>
          <p className="text-xl mt-8 text-bodyColor leading-relaxed">
            IOSF is an organisation for Developers and Designers who want to get
            started with or contribute to open source projects.
          </p>
          <p className="text-xl mt-4 text-bodyColor leading-relaxed">
            We aspire to build an active community of contributors, building
            products that solve a problem.
          </p>
          <button
            onClick={() => navigate("/join-us")}
            className="py-3 px-6 mt-8 rounded font-medium border border-primary text-primary bg-primaryLight aboutSectionJoinUsBtn"
          >
            Become a Contributor
          </button>
        </div>
        <div className="aboutSectionRight">
          <img src={StarImage} alt="star-img" />

          {/* image will come here */}
        </div>
      </div>
      <div className="mt-16 togetherWeContent">
        <h3 className="text-2xl">Together We</h3>
        <div className="togtherWeList">
          {TOGETHER_WE_CONTENT.map((item, index) => (
            <div
              key={index}
              className="togetherWeCard flex items-center justify-between border border-[#eee] p-3"
            >
              <div className="togetherWeCardIcon">
                <img
                  width={80}
                  height={80}
                  src={item.icon}
                  alt={`${item.title}-icon`}
                />
              </div>
              <div className="ml-4 togetherWeCardContent">
                <h4 className="font-medium text-bodyColor">{item.title}</h4>
                <p className="text-sm text-bodyColorLight">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
