import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const HEADER_LINKS = [
  {
    index: 0,
    url: "/",
    name: "Home",
  },
  {
    index: 1,
    url: "/join-us",
    name: "Join Us",
  },
  // {
  //   index: 2,
  //   name: "Design",
  // },
];

const Header = () => {
  const width = window.innerWidth;

  return <>{width > 1024 ? <HeaderDesktop links={HEADER_LINKS} /> : <HeaderMobile />}</>;
};

export default Header;
