const PATH_DETAILS = [
  {
    name: "Development",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate iusto unde alias a earum nostrum fugit minima ab cupiditate repellendus. Tempore necessitatibus culpa doloremque hic est, molestias id vitae alias.",
    tags: ["JavaScript", "Python", "Frontend", "Backend", "DevOps"],
    backgroundClass: "bg-gradient-to-r from-[#ea580c] to-[#f97316]",
  },
  {
    name: "Design",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate iusto unde alias a earum nostrum fugit minima ab cupiditate repellendus. Tempore necessitatibus culpa doloremque hic est, molestias id vitae alias.",
    tags: [
      "UI Design",
      "User Experience",
      "Product Design",
      "Graphic Design",
      "Icons",
      "Illustrations",
      "Typography",
    ],
    backgroundClass: "bg-gradient-to-r from-[#4f46e5] to-[#8b5cf6]",
  },
];

const Paths = () => {
  return (
    <>
      <div className="paths">
        <h2 className="text-4xl font-medium text-headingColor text-center">
          Select a path for contribution
        </h2>
        <p className="text-xl mt-8 max-w-md text-center mx-auto text-bodyColor">
          We have carefully crafted each path and packed them with exciting
          projects
        </p>
        <div className="lg:flex justify-between gap-8 lg:px-16 mt-8">
          {PATH_DETAILS.map((item, index) => (
            <div
              key={index}
              style={{ height: "350px", padding: "30px" }}
              className={`flex-1 rounded mt-8 ${item.backgroundClass}`}
            >
              <h3 className="text-3xl text-white font-medium">{item.name}</h3>
              <div style={{ color: "#FFFFFF" }} className="mt-4">
                {/* {item.description} */}
                {item.tags.map((tag, index) => (
                  <div
                    key={index}
                    className="border inline-block px-2 rounded mr-4 mt-4"
                  >
                    {tag}
                  </div>
                ))}
              </div>
              <button className="py-2 px-4 rounded mt-8 bg-white text-bgDark font-medium">
                <span>Get Started w/ {item.name}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Paths;
