// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDevelopment = {
  apiKey: "AIzaSyBOyxYhfMVzFOjIsD9NXmgk3QPaJ-4lxD8",
  authDomain: "iosf-development.firebaseapp.com",
  projectId: "iosf-development",
  storageBucket: "iosf-development.appspot.com",
  messagingSenderId: "413328872858",
  appId: "1:413328872858:web:3afe8c437bcf1781bb1623",
  measurementId: "G-H4KP5YJBCT",
};

const firebaseConfigProduction = {
  apiKey: "AIzaSyApwFQ_p4Lqwl5d4GsGRBcRnORE1UB_PHg",
  authDomain: "iosf-production.firebaseapp.com",
  projectId: "iosf-production",
  storageBucket: "iosf-production.appspot.com",
  messagingSenderId: "267537660786",
  appId: "1:267537660786:web:6b49e7459521d43b0a7beb",
  measurementId: "G-6EYTYYDN3S",
};

// Initialize Firebase
export const app = initializeApp(
  process.env.NODE_ENV === "development"
    ? firebaseConfigDevelopment
    : firebaseConfigProduction
);

export const db = getFirestore();
getAnalytics(app);
