import { X } from "phosphor-react";
import "./modal.css";

const Modal = ({ children, isOpen, onOpen, onClose, style }) => {
  return (
    <>
      {isOpen && (
        <div style={{ ...style }} className="modal__backdrop">
          <div className="modal__container">
            <div className="modal__content">
              <div className="modal__header">
                <div onClick={onClose} className="modal__closeIcon">
                  <X size={24} />
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
