import { collection, addDoc } from "firebase/firestore";
import { useState } from "react";
import { toast } from "react-toastify";
import { db } from "../../../../firebase.js";
import { CheckCircle } from "phosphor-react";
import Modal from "../../../../common/Modal/Modal.js";
import { useNavigate } from "react-router-dom";

const INITIAL_STATE = {
  name: "",
  email: "",
  path: "",
};

const JoinUsForm = () => {
  const [leadDetails, setLeadDetails] = useState(INITIAL_STATE);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const navigate = useNavigate();

  const submitDetails = async () => {
    try {
      const { name, email, path } = leadDetails;
      if (name === "" || email === "" || path === "") {
        throw Error("Please enter all the fields");
      }

      let collectionString = `iosf-website-leads`;
      // save the lead
      const docRef = await addDoc(collection(db, collectionString), {
        name,
        email,
        path,
      });
      if (docRef.id) {
        setIsSuccessModalOpen(true);
        setLeadDetails(INITIAL_STATE);
      }
    } catch (err) {
      // catch error
      toast.error(err.message, {
        position: "top-left",
      });
    }
  };

  return (
    <form>
      <div className="py-2">
        <label className="block py-1 text-bodyColor text-sm" htmlFor="name">
          Name
        </label>
        <input
          className="border border-[#A3A3A3] py-2 px-4 rounded w-full"
          name="name"
          placeholder="Enter your name"
          value={leadDetails.name}
          onChange={(e) => {
            setLeadDetails({ ...leadDetails, name: e.target.value });
          }}
        />
      </div>
      <div className="py-2">
        <label className="block py-1 text-bodyColor text-sm" htmlFor="email">
          Email
        </label>
        <input
          className="border border-[#A3A3A3] py-2 px-4 rounded w-full"
          name="email"
          placeholder="Enter your email"
          value={leadDetails.email}
          onChange={(e) => {
            setLeadDetails({ ...leadDetails, email: e.target.value });
          }}
        />
      </div>
      <div className="py-2">
        <label className="block py-1 text-bodyColor text-sm" htmlFor="name">
          Which path would you contribute to?
        </label>
        <select
          className="border border-[#A3A3A3] py-2 px-4 rounded w-full"
          name="path"
          value={leadDetails.path}
          onChange={(e) => {
            setLeadDetails({ ...leadDetails, path: e.target.value });
          }}
        >
          <option value="">Select a path to contribute</option>
          <option value="Development">Development</option>
          <option value="Design">Design</option>
        </select>
      </div>
      <button
        onClick={submitDetails}
        type="button"
        style={{
          background:
            "linear-gradient(139.19deg, #7A80B9 -45.15%, rgba(122, 128, 185, 0) 130.76%), #2B2D42",
        }}
        className="px-6 py-3 mt-4 rounded text-white font-medium w-full"
      >
        Submit Details
      </button>

      {/* success modal */}
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => {
          setIsSuccessModalOpen(false);
        }}
      >
        <div className="flex flex-col items-center text-center">
          <h4 className="text-3xl font-semibold text-headingColor">
            THANK YOU!
          </h4>
          <div className="mt-4">
            <CheckCircle color="#0CA678" size={60} />
          </div>
          <div className="mt-4">
            <p className="text-bodyColor">Your response has been recorded</p>
            <p className="text-xl mt-2 font-medium text-headingColor">
              You will soon recieve an invite from us
            </p>
          </div>
          <div className="mt-8">
            <button
              onClick={() => navigate("/")}
              className="bg-primaryLight text-primary font-medium px-4 py-2 border rounded"
            >
              Go to Homepage
            </button>
          </div>
        </div>
      </Modal>
    </form>
  );
};

export default JoinUsForm;
